<script>
    const requestUrl = `${process.env.API_URL}/search/demo`

    let results = null;
    let isResultsLoading = false;

    async function getSearchResults({query, location}) {
        try {
            results = null
            isResultsLoading = true
            const response = await fetch(`${requestUrl}?query=${query}&location=${location}`, {method: 'POST'})
            results = await response.json()

            document.querySelector('#icons').remove()
        } catch {
            //
        } finally {
            isResultsLoading = false
        }
    }

    async function handleFormSubmit(e) {
        e.preventDefault();
        const query = e.target.elements.query.value;
        const location = e.target.elements.location.value;

        getSearchResults({query, location})
    }
</script>


<div class="flex flex-col md:items-center gap-16">
    <form class="flex items-center relative xl:w-[700px]" on:submit={handleFormSubmit}>
        <input class="px-2 bg-white py-4 xl:px-6 w-2/5 border-2 border-gray-200 rounded-lg rounded-r-none"
               type="text" name="query" placeholder="restaurants, plumbers, etc" required>
        <input class="px-2 py-4 xl:px-6 w-3/5 border-2 border-gray-200 border-l-0 rounded-r-lg"
               type="text" name="location" placeholder="address or city" required>
        <button aria-label="search" class="absolute right-1 p-4 rounded-lg text-white font-medium bg-slate-800 text-white disabled:opacity-50"
                disabled={isResultsLoading} type="submit">
            {#if isResultsLoading}
                <svg class="spinner w-5 h-5 relative" viewBox="0 0 50 50">
                    <circle class="path" stroke="currentColor" cx="25" cy="25" r="20" fill="none"
                            stroke-width="5"></circle>
                </svg>
            {:else}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                     class="w-5 h-5">
                    <path fill-rule="evenodd"
                          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                          clip-rule="evenodd"></path>
                </svg>
            {/if}
        </button>
    </form>

    {#if results && results.length === 0}
        <p class="text-center">We did not find anything for your query. Try again.</p>
    {/if}

    {#if results && results.length !== 0}
        <div class="overflow-x-auto w-full relative shadow-md sm:rounded-lg animated animatedFadeInUp fadeInUp bg-white">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="py-3 px-6">Company</th>
                    <th scope="col" class="py-3 px-6">Address</th>
                    <th scope="col" class="py-3 px-6">Phone</th>
                    <th scope="col" class="py-3 px-6">Email</th>
                </tr>
                </thead>
                <tbody>
                {#each results as result}
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th class="py-4 px-6 font-medium text-gray-900">
                            {#if result.website}
                                <a href={result.website} class="underline text-blue-600" target="_blank" rel="noreferrer">
                                    {result.title}
                                </a>
                            {:else}
                                {result.title}
                            {/if}
                        </th>
                        <td class="py-4 px-6">{result.address}</td>
                        <td class="py-4 px-6 whitespace-nowrap">{result.phone}</td>
                        <td class="py-4 px-6 blur-sm select-none">some@email.com</td>
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>

        <a href={process.env.SIGN_UP_PATH}>
            <button class="p-4 rounded-lg text-white font-medium bg-slate-800 text-white drop-shadow-lg">Get Full Result</button>
        </a>
    {/if}
</div>

<style>
    .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
    }

    .spinner .path {
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }

</style>
